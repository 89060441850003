/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video-placeholder': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M27.404 10.493H1.728a.584.584 0 00-.583.584v14.005A2.921 2.921 0 004.062 28H25.07c1.61 0 2.918-1.31 2.918-2.918V11.077a.584.584 0 00-.584-.584zm-.583 14.59c0 .964-.786 1.75-1.75 1.75H4.061c-.965 0-1.75-.786-1.75-1.75V11.66H26.82v13.421z" _fill="#3F3F4E"/><path pid="1" d="M27.97 5.093l-.994-3.768A1.763 1.763 0 0024.884.043L1.37 5.622a1.737 1.737 0 00-1.102.79 1.73 1.73 0 00-.205 1.342l1.1 4.347a.582.582 0 00.709.422.582.582 0 00.421-.708l-.064-.254 25.304-5.749a.589.589 0 00.435-.719zM1.943 10.43l-.75-2.958a.586.586 0 01.442-.713L25.148 1.18a.59.59 0 01.697.434l.842 3.192L1.944 10.43z" _fill="#3F3F4E"/><path pid="2" d="M6.658 10.554a.588.588 0 00-.784.262L3.54 15.484a.585.585 0 001.046.522l2.334-4.668a.585.585 0 00-.262-.784zM12.493 10.554a.587.587 0 00-.783.262l-2.334 4.668a.585.585 0 001.044.522l2.335-4.668a.585.585 0 00-.262-.784zM18.33 10.554a.587.587 0 00-.784.262l-2.334 4.668a.585.585 0 001.044.522l2.335-4.668a.585.585 0 00-.262-.784zM24.163 10.554a.587.587 0 00-.783.262l-2.334 4.668a.585.585 0 001.044.522l2.335-4.668a.585.585 0 00-.262-.784z" _fill="#3F3F4E"/><path pid="3" d="M27.404 15.162H1.728a.584.584 0 000 1.167h25.676a.583.583 0 100-1.167zM7.373 9.475l-4.02-4.02a.584.584 0 00-.825.825l4.02 4.02a.586.586 0 00.825 0 .584.584 0 000-.825zM13.081 8.178l-4.023-4.02a.584.584 0 00-.825.826l4.022 4.02a.586.586 0 00.826 0 .584.584 0 000-.826zM18.787 6.881l-4.016-4.02a.584.584 0 00-.825.825l4.016 4.02a.586.586 0 00.825 0 .584.584 0 000-.825zM24.49 5.586l-4.018-4.02a.584.584 0 00-.825.824l4.017 4.021a.586.586 0 00.825 0 .584.584 0 000-.825z" _fill="#3F3F4E"/>'
  }
})
